import { StripeConstants } from "../helpers/Constants";
import { flatten } from "../helpers/FlattenHelper";

export const hitGetApi = async (url) => {
    return fetch(StripeConstants.baseUrl + url, {
        headers: {
            'Authorization': 'Bearer ' + StripeConstants.secretKey,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
        .then((response) => response.json())
        .then((json) => {
            return json;
        })
        .catch((error) => {
            throw error;
        });
}

export const hitPostApi = async (url, params) => {
    return fetch(StripeConstants.baseUrl + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + StripeConstants.secretKey,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(flatten(params)).toString()
    })
        .then((response) => response.json())
        .then((json) => {
            return json
        })
        .catch((error) => {
            throw error;
        });
}

export const hitPostApiWithCustomHeaders = async (url, params, customHeaders) => {
    return fetch(StripeConstants.baseUrl + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + StripeConstants.secretKey,
            'Content-Type': 'application/x-www-form-urlencoded',
            ...customHeaders,
        },
        body: new URLSearchParams(flatten(params)).toString()
    })
        .then((response) => response.json())
        .then((json) => {
            return json
        })
        .catch((error) => {
            throw error;
        });
}

export const hitMultipartPostApi = async (file, purpose) => {
    const formdata = new FormData();
    formdata.append("file", {uri: file.path, name: file.filename, type: file.mime})
    formdata.append("purpose", purpose)

    return fetch(StripeConstants.uploadFileUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + StripeConstants.secretKey,
            'Content-Type': 'multipart/form-data',
        },
        body: formdata
    })
        .then((response) => response.json())
        .then((json) => {
            return json
        })
        .catch((error) => {
            throw error;
        });
}