import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { ORDER_STATUSES_NAMES, PayoutStatuses, pageNames } from "../helpers/Constants";
import { calcTimeSpent, compareOrders } from "../helpers/HelperFunctions";
import { getPayoutOrders } from "../service/OrdersService";
import { checkPayoutStatus, sendPayout } from "../service/PayoutService";

const PayoutsPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    const navigate = useNavigate();

    const payout = async (order) => {
        if (window.confirm(`Are you sure you want to payout ${order.payoutAmount} Yen?`)) {
            try {
                setLoading(true);
                await sendPayout(order)
                fetchData()
            } catch (error) {
                setLoading(false);
                alert(error)
            }
        }
    }

    const getPayoutStatuses = async (orders) => {
        if (orders && orders.length > 0) {
            for (let i = 0; i < orders.length; ++i) {
                const order = orders[i]
                if (order.payoutStatus && order.payoutStatus != PayoutStatuses.paid) {
                    try {
                        await checkPayoutStatus(order.id, order.payoutId, order.payoutStatus)
                    } catch (error) {
                        setLoading(false);
                        alert(error);
                    }
                }
            }
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const tempOrders = await getPayoutOrders();
            await getPayoutStatuses(tempOrders);
            const orders = await getPayoutOrders();
            orders.sort(compareOrders)
            setOrders(orders);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert(error);
        }
    }

    const onViewDetails = (order) => {
        navigate(pageNames.payoutDetail, {
            state: {
                order: order,
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Payouts
                        <small>Send Payouts</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Payouts</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Order Id</th>
                                                    <th>Order Status</th>
                                                    <th>Selling Price</th>
                                                    <th>Stripe Fee</th>
                                                    <th>Surfrack Fee</th>
                                                    <th>Payout Amount</th>
                                                    <th>Payment Done</th>
                                                    <th>Payout Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{order.id}</td>
                                                        <td>{ORDER_STATUSES_NAMES[order.orderStatus]}</td>
                                                        <td>{order.sellingPrice}</td>
                                                        <td>{order.stripeFeeAmount}</td>
                                                        <td>{order.surfrackFeeAmount}</td>
                                                        <td>{order.payoutAmount}</td>
                                                        <td>{order.paymentDoneAt ? calcTimeSpent(order.paymentDoneAt.seconds) : "No Payment"}</td>
                                                        <td>{order.payoutStatus ? order.payoutStatus : "N/A"}</td>
                                                        <td>
                                                            {order.payoutStatus ?
                                                                "Payout Initiated " + calcTimeSpent(order.payoutInitiatedAt.seconds)
                                                                :
                                                                <button onClick={() => payout(order)} className="btn btn-primary">
                                                                    Payout
                                                                </button>
                                                            }
                                                            <br />
                                                            <br />
                                                            <button onClick={() => onViewDetails(order)} className="btn btn-primary">
                                                                View Details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default PayoutsPage;