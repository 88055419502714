import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getUsers } from "../service/UserService";
import { UserTypes, pageNames } from "../helpers/Constants";
import { CSVLink } from "react-csv";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";

const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Username", key: "username" },
    { label: "User Type", key: "userType" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phoneNumber" },
];

const UsersPage = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [dataForCsv, setDataForCsv] = useState([]);
    const theme = useTheme(getTheme());
    const COLUMNS = [
        { label: 'Sr. No.', renderCell: (item) => item.index + "", sort: { sortKey: "index" }, },
        { label: 'Name', renderCell: (item) => item.name, sort: { sortKey: "name" }, },
        { label: 'Is Business', renderCell: (item) => item.isBusiness, sort: { sortKey: "isBusiness" } },
        { label: 'Uploaded Surfboards', renderCell: (item) => item.surfboards ? item.surfboards.length : '0', sort: { sortKey: "uploadedSurfboards" } },
        { label: 'Email', renderCell: (item) => item.email, sort: { sortKey: "email" } },
        { label: 'Phone', renderCell: (item) => item.phoneNumber, sort: { sortKey: "phone" } },
        {
            label: 'Actions', renderCell: (item) =>
                <button onClick={() => onViewDetails(item)} className="btn btn-primary">
                    View Details
                </button>
        },
    ];
    const sort = useSort(
        users,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                index: (array) => array.sort((a, b) => a.index - b.index),
                name: (array) => array.sort((a, b) => a.name?.localeCompare(b.name)),
                isBusiness: (array) => array.sort((a, b) => a.isBusiness.localeCompare(b.isBusiness)),
                uploadedSurfboards: (array) => array.sort((a, b) => (a.surfboards || []).length - (b.surfboards || []).length),
                email: (array) => array.sort((a, b) => a.email?.localeCompare(b.email)),
                phone: (array) => array.sort((a, b) => a.phoneNumber?.localeCompare(b.phoneNumber)),
            },
        }
    );
    function onSortChange(action, state) {
        // Do nothing
    }
    const pagination = usePagination(users, {
        state: {
            page: 0,
            size: 100,
        },
        onChange: onPaginationChange,
    });
    function onPaginationChange(action, state) {
        // Do nothing
    }

    const onViewDetails = (user) => {
        navigate(pageNames.userDetail, {
            state: {
                user: user
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const usersData = await getUsers();
                const csvUsers = [];
                for (let i = 0; i < usersData.length; ++i) {
                    const user = usersData[i];
                    user.index = (i + 1);
                    user.isBusiness = user.userType === UserTypes.BUSINESS ? 'Yes' : 'No';
                    const obj = {
                        id: user.id,
                        name: user.name,
                        username: user.username,
                        userType: user.userType,
                        email: user.email ? user.email : "",
                        phoneNumber: user.phoneNumber ? user.phoneNumber : ""
                    }
                    csvUsers.push(obj);
                }
                setDataForCsv(csvUsers);
                setUsers(usersData);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Users
                        <small>View All Users</small>
                    </h1>
                    {!loading ?
                        <CSVLink
                            data={dataForCsv}
                            headers={headers}
                            filename={"surfrack-users.csv"}
                            className="btn btn-primary"
                            style={{ marginTop: 10 }}>
                            Export to CSV
                        </CSVLink>
                        : <div />
                    }
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Users</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <div>
                                            <CompactTable
                                                columns={COLUMNS}
                                                data={{ nodes: users }}
                                                theme={theme}
                                                sort={sort}
                                                pagination={pagination} />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span>Total Pages: {pagination.state.getTotalPages(users)}</span>
                                                <span>
                                                    Page:{" "}
                                                    {pagination.state.getPages(users).map((_, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            style={{
                                                                fontWeight: pagination.state.page === index ? "bold" : "normal",
                                                            }}
                                                            onClick={() => pagination.fns.onSetPage(index)}
                                                        >
                                                            {index + 1}
                                                        </button>
                                                    ))}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <FooterComponent />
        </div>
    );
}

export default UsersPage;