import { getFirestore, getDocs, collection, query, where, doc, setDoc } from "firebase/firestore"
import { ORDER_STATUSES } from "../helpers/Constants";

const db = getFirestore();
const collectionName = "orders";

export const getOrdersForPayout = async () => {
    try {
        const ordersCollection = collection(db, collectionName);
        const q = query(ordersCollection, where("orderStatus", "==", ORDER_STATUSES.DELIVERED));
        const snapshot = await getDocs(q);
        const list = snapshot.docs.map(doc => doc.data());
        return list;
    } catch (e) {
        throw e
    }
}

export const getOrders = async () => {
    try {
        const ordersCollection = collection(db, collectionName);
        const snapshot = await getDocs(ordersCollection);
        const list = snapshot.docs.map(doc => doc.data());
        return list;
    } catch (e) {
        throw e
    }
}

export const dbUpdateOrder = async (orderId, data) => {
    try {
        await setDoc(doc(db, collectionName, orderId), data, { merge: true });
    } catch (e) {
        throw e
    }
}