import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getSurfboards } from "../service/SurfboardService";
import { pageNames } from "../helpers/Constants";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";

const SurfboardsPage = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [surfboards, setSurfboards] = useState([]);
    const theme = useTheme(getTheme());
    const COLUMNS = [
        { label: 'Sr. No.', renderCell: (item) => item.index + "", sort: { sortKey: "index" }, },
        { label: 'Submitted By', renderCell: (item) => item.user.name, sort: { sortKey: "name" }, },
        { label: 'Price', renderCell: (item) => item.sellingPrice, sort: { sortKey: "sellingPrice" } },
        { label: 'Brand', renderCell: (item) => item.brandName, sort: { sortKey: "brandName" } },
        { label: 'Uploaded On', renderCell: (item) => new Date(item.createdAt.seconds * 1000).toString(), sort: { sortKey: "uploaded" } },
        { label: 'Is Available', renderCell: (item) => item.strIsAvailable, sort: { sortKey: "isAvailable" } },
        {
            label: 'Actions', renderCell: (item) =>
                <button onClick={() => onViewDetails(item)} className="btn btn-primary">
                    View Details
                </button>
        },
    ];
    const sort = useSort(
        surfboards,
        {
            onChange: onSortChange,
        },
        {
            sortFns: {
                index: (array) => array.sort((a, b) => a.index - b.index),
                name: (array) => array.sort((a, b) => a.user?.name.localeCompare(b.user.name)),
                sellingPrice: (array) => array.sort((a, b) => a.sellingPrice - b.sellingPrice),
                brandName: (array) => array.sort((a, b) => a.brandName?.localeCompare(b.brandName)),
                uploaded: (array) => array.sort((a, b) => a.createdTimestamp - b.createdTimestamp),
                isAvailable: (array) => array.sort((a, b) => a.strIsAvailable.localeCompare(b.strIsAvailable)),
            },
        }
    );
    function onSortChange(action, state) {
        // Do nothing
    }
    const pagination = usePagination(surfboards, {
        state: {
            page: 0,
            size: 100,
        },
        onChange: onPaginationChange,
    });
    function onPaginationChange(action, state) {
        // Do nothing
    }

    const onViewDetails = (surfboard) => {
        navigate(pageNames.surfboardDetail, {
            state: {
                surfboard: surfboard
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const surfboardsData = await getSurfboards();
                for (let i = 0; i < surfboardsData.length; ++i) {
                    const surfboard = surfboardsData[i];
                    surfboard.index = (i + 1);
                    surfboard.createdTimestamp = new Date(surfboard.createdAt.seconds * 1000);
                    surfboard.strIsAvailable = surfboard.isAvailable ? 'Yes' : 'No';
                }
                setSurfboards(surfboardsData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Surfboards
                        <small>View All Surfboards</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Surfboards</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <div>
                                            <CompactTable
                                                columns={COLUMNS}
                                                data={{ nodes: surfboards }}
                                                theme={theme}
                                                sort={sort}
                                                pagination={pagination} />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span>Total Pages: {pagination.state.getTotalPages(surfboards)}</span>
                                                <span>
                                                    Page:{" "}
                                                    {pagination.state.getPages(surfboards).map((_, index) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            style={{
                                                                fontWeight: pagination.state.page === index ? "bold" : "normal",
                                                            }}
                                                            onClick={() => pagination.fns.onSetPage(index)}
                                                        >
                                                            {index + 1}
                                                        </button>
                                                    ))}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default SurfboardsPage;