import { getFirestore, doc, getDoc, getDocs, collection, query, where } from "firebase/firestore"

const db = getFirestore();
const collectionName = "surfboards";
const configCollectionName = "surfboardConfig";

export const dbGetSurfboardConfig = async () => {
    try {
        const configCollection = collection(db, configCollectionName);
        const configSnapshot = await getDocs(configCollection);
        const configList = configSnapshot.docs.map(doc => doc.data());
        return configList[0];
    } catch (e) {
        throw e
    }
}

export const dbGetSurfboardDetail = async (surfboardId) => {
    try {
        const surfboardRef = doc(db, collectionName, surfboardId);
        const surfboardSnap = await getDoc(surfboardRef);
        if (surfboardSnap.exists()) {
            return surfboardSnap.data();
        } else {
            throw "No such surfboard"
        }
    } catch (e) {
        throw e
    }
}

export const getAllSurfboards = async () => {
    try {
        const surfboardsCollection = collection(db, collectionName);
        const surfboardsSnapshot = await getDocs(surfboardsCollection);
        const surfboardsList = surfboardsSnapshot.docs.map(doc => doc.data());
        return surfboardsList;
    } catch (e) {
        throw e
    }
}

export const getAllSurfboardsByUserId = async (userId) => {
    try {
        const surfboardsCollection = collection(db, collectionName);
        const q = query(surfboardsCollection, where("userId", "==", userId));
        const surfboardsSnapshot = await getDocs(q);
        const surfboardsList = surfboardsSnapshot.docs.map(doc => doc.data());
        return surfboardsList;
    } catch (e) {
        throw e
    }
}