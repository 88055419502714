import { getAllBrands } from "../firebaseDao/BrandsDao";
import { dbGetSurfboardConfig, getAllSurfboards, getAllSurfboardsByUserId } from "../firebaseDao/SurfboardsDao";
import { getUserDetailsById } from "../firebaseDao/UsersDao";
import { FirestoreDatabaseIds } from "../helpers/Constants";
import { getValuesBasedOnIdFromArray } from "../helpers/HelperFunctions";

export const getSurfboardConfig = async () => {
    try {
        const config = await dbGetSurfboardConfig();
        return config;
    } catch (error) {
        throw error
    }
}

export const getSurfboards = async () => {
    try {
        const surfboards = await getAllSurfboards();
        const brands = await getAllBrands();

        let users = [];
        for (let i = 0; i < surfboards.length; ++i) {
            let surfboard = surfboards[i];

            let existsAt = -1;
            for (let j = 0; j < users.length; ++j) {
                let user = users[j];
                if (surfboard.userId === user.id) {
                    existsAt = j;
                    break;
                }
            }

            if (existsAt !== -1) {
                surfboard.user = users[existsAt];
            } else {
                const user = await getUserDetailsById(surfboard.userId);
                surfboard.user = user;
                users.push(user);
            }

            if (surfboard.brand === FirestoreDatabaseIds.othersBrandId) {
                surfboard.brandName = surfboard.otherBrandName
            } else {
                surfboard.brandName = getValuesBasedOnIdFromArray(brands, surfboard.brand)
            }
        }

        return surfboards;
    } catch (error) {
        throw error
    }
}

export const getSurfboardsByUserId = async (userId) => {
    try {
        const surfboards = await getAllSurfboardsByUserId(userId);
        const brands = await getAllBrands();

        let users = [];
        for (let i = 0; i < surfboards.length; ++i) {
            let surfboard = surfboards[i];

            let existsAt = -1;
            for (let j = 0; j < users.length; ++j) {
                let user = users[j];
                if (surfboard.userId === user.id) {
                    existsAt = j;
                    break;
                }
            }

            if (existsAt !== -1) {
                surfboard.user = users[existsAt];
            } else {
                const user = await getUserDetailsById(surfboard.userId);
                surfboard.user = user;
                users.push(user);
            }

            if (surfboard.brand === FirestoreDatabaseIds.othersBrandId) {
                surfboard.brandName = surfboard.otherBrandName
            } else {
                surfboard.brandName = getValuesBasedOnIdFromArray(brands, surfboard.brand)
            }
        }

        return surfboards;
    } catch (error) {
        throw error
    }
}

export const getSurfboardStats = async () => {
    try {
        const allSurfboards = await getAllSurfboards();
        const availableSurfboards = []
        const soldSurfboards = []

        const result = {
            totalSurfboards: allSurfboards.length + ""
        }

        if (allSurfboards && allSurfboards.length > 0) {
            for (let i = 0; i < allSurfboards.length; ++i) {
                const surfboard = allSurfboards[i]
                if (surfboard.isAvailable) {
                    availableSurfboards.push(surfboard)
                } else {
                    soldSurfboards.push(surfboard)
                }
            }
        }

        result.availableSurfboards = availableSurfboards.length + ""
        result.soldSurfboards = soldSurfboards.length + ""

        return result
    } catch (error) {
        throw error
    }
}