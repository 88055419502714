import React, { useState } from "react";
import LoadingComponent from "../common/LoadingComponent";
import { isNullOrEmpty, isValidEmail } from "../helpers/HelperFunctions";
import { loginWithEmailAndPassword } from "../service/UserService";
import { useNavigate } from 'react-router-dom';
import { pageNames } from "../helpers/Constants";

const LoginPage = (props) => {
    const [user, setUser] = useState({});
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const loginPressed = async () => {
        if (isNullOrEmpty(user.email)) {
            setEmailError('Please enter Email');
        } else if (!isValidEmail(user.email)) {
            setEmailError('Please enter a valid Email');
        } else if (isNullOrEmpty(user.password)) {
            setPasswordError('Please enter Password');
        } else {
            try {
                setLoading(true);
                const success = await loginWithEmailAndPassword(user.email, user.password);
                setLoading(false);
                if (success) {
                    // Move to Home Screen
                    navigate(pageNames.home, {
                        replace: true,
                    });
                }
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    }

    return (
        <div className="login-page" style={{ height: '100%' }}>
            <div className="login-box">
                <div className="login-logo">
                    <p><b>SurfRack</b> Admin</p>
                </div>
                <div className="login-box-body">
                    <p className="login-box-msg">Sign in to start your session</p>
                    <div className="form-group has-feedback">
                        <input type="text" className="form-control"
                            placeholder="Email"
                            onChange={(event) => {
                                let tempUser = { ...user };
                                tempUser.email = event.target.value
                                setUser(tempUser);
                                setEmailError('');
                            }} />
                        <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                        <p className="error-text">{emailError}</p>
                    </div>
                    <div className="form-group has-feedback">
                        <input type="password" className="form-control"
                            placeholder="Password"
                            onChange={(event) => {
                                let tempUser = { ...user };
                                tempUser.password = event.target.value
                                setUser(tempUser);
                                setPasswordError('');
                            }} />
                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                        <p className="error-text">{passwordError}</p>
                    </div>
                    <div className="form-group has-feedback">
                        {loading ?
                            <LoadingComponent />
                            : <button onClick={loginPressed} className="btn btn-primary btn-block btn-flat">
                                Sign In
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;