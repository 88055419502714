import { getFirestore, getDocs, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"

const db = getFirestore();
const brandsCollectionName = "brands";

export const addBrandToFirestore = async (nameEN, nameJA) => {
    try {
        const newBrandRef = doc(collection(db, brandsCollectionName));
        const data = {
            id: newBrandRef.id,
            nameEN,
            nameJA,
        };
        await setDoc(newBrandRef, data);
    } catch (e) {
        throw e
    }
}

export const getAllBrands = async () => {
    try {
        const brandsCollection = collection(db, brandsCollectionName);
        const brandsSnapshot = await getDocs(brandsCollection);
        const brandsList = brandsSnapshot.docs.map(doc => doc.data());
        return brandsList;
    } catch (e) {
        throw e
    }
}

export const updateBrandToFirestore = async (id, nameEN, nameJA) => {
    try {
        const docRef = doc(db, brandsCollectionName, id);
        const docSnap = await getDoc(docRef);
        let currentData = docSnap.data()
        
        currentData = {
            ...currentData,
            nameEN,
            nameJA,
        }

        await updateDoc(docRef, {...currentData});
    } catch (e) {
        throw e
    }
}