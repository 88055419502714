import { getFirestore, collection, doc, setDoc } from "firebase/firestore"

const db = getFirestore();
const collectionName = "adminNotifications";

export const addAdminNotification = async (notification) => {
    try {
        const newAdminNotificationRef = doc(collection(db, collectionName));
        const data = {
            id: newAdminNotificationRef.id,
            ...notification
        };
        await setDoc(newAdminNotificationRef, data);
    } catch (error) {
        throw error
    }
}