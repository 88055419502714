export const pageNames = {
    loginPage: "/login",
    home: "/",
    cities: "/cities",
    brands: "/brands",
    suggestions: "/suggestions",
    reportedUsers: "/reportedUsers",
    changePassword: "/changePassword",
    addCity: "/addCity",
    states: "/states",
    addState: "/addState",
    addBrand: "/addBrand",
    deactivatedUsers: "/deactivatedUsers",
    payouts: "/payouts",
    payoutDetail: "/payoutDetail",
    orders: "/orders",
    orderDetail: "/orderDetail",
    sendPush: "/sendPush",
    surfboards: "/surfboards",
    users: "/users",
    userDetail: "/userDetail",
    surfboardDetail: "/surfboardDetail",
}

export const ORDER_STATUSES = {
    PENDING: 1,
    APPROVED: 2,
    CONFIRMED: 3,
    SHIPPED: 4,
    DELIVERED: 5,
    CANCELLED: 6,
}

export const ORDER_STATUSES_NAMES = {
    1: 'Pending',
    2: 'Approved',
    3: 'Confirmed',
    4: 'Shipped',
    5: 'Delivered',
    6: 'Cancelled',
}

export const NotificationTypes = {
    NEW_MESSAGE: 1,
    NEW_ORDER_RECEIVED: 2,
    ORDER_STATUS_UPDATED: 3,
    ADMIN_GENERAL_NOTIFICATION: 4
}

export const StripeConstants = {
    // Stripe Test Keys
    // publishableKey: 'pk_test_51LafT5KQTr7oxvPEKqJ1mr0KvPSypqmlqJ165B3RV5B9DMyRCxegfVEYYdBj6rJJeaA3UlkKLE2p4Ng9BtPhATaz00tZqhlDJS',
    // secretKey: 'sk_test_51LafT5KQTr7oxvPEUjLf3OiJeMZmrYh9uRgXubI9mKEFjtdaf0NUyK3xu1TdipUIV2tI2rntgOE4s4QnLe7WJaHp00OcLMrpDd',

    // Stripe Live Keys
    publishableKey: 'pk_live_51LafT5KQTr7oxvPE13bskNjA1qknEHKm88Pgll9irI3AfZZO445CgfmZ3TgGeh05kaawgTn7xEPYJlsYxvK4UC1L00SLzq4Ot1',
    secretKey: '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',

    currency: 'jpy',
    
    baseUrl: 'https://api.stripe.com/v1/',
    sendPayout: 'payouts',
    retrievePayout: 'payouts/'
}

export const FirestoreDatabaseIds = {
    othersBrandId: '101', // The id for Others Brand always needs to be 101
}

export const PayoutStatuses = {
    paid: 'paid',
    pending: 'pending',
    in_transit: 'in_transit',
    canceled: 'canceled',
    failed: 'failed'
}

export const UserTypes = {
    USER: 'user',
    BUSINESS: 'business',
}