import * as firestore from 'firebase/firestore'
import { NotificationTypes } from "../helpers/Constants";
import { addAdminNotification } from '../firebaseDao/AdminNotifications';
import { sendPushNotificationToMultipleTokens } from './PushService';

export const sendAdminPushNotification = async (title, body) => {
    try {
        const timestamp = firestore.Timestamp.now()
        const notification = {
            title,
            body,
            notificationType: NotificationTypes.ADMIN_GENERAL_NOTIFICATION,
            createdAt: timestamp
        }
        await addAdminNotification(notification);
        await sendPushNotificationToMultipleTokens(title, body, notification);
    } catch (error) {
        throw error
    }
}