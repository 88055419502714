import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as firestore from 'firebase/firestore';
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { FirestoreDatabaseIds, PayoutStatuses } from "../helpers/Constants";
import { calcTimeSpent, getValuesBasedOnIdFromArray, isNullOrEmpty } from "../helpers/HelperFunctions";
import { getBrands } from "../service/BrandsService";
import { getOrderDetails } from "../service/OrdersService";
import { sendPayout } from "../service/PayoutService";

const PayoutDetailPage = (props) => {
    const [loading, setLoading] = useState(false)
    const params = useLocation()
    const [order, setOrder] = useState(null)
    const [brands, setBrands] = useState([])

    const payout = async (isRestart) => {
        let message = `Are you sure you want to payout ${order.payoutAmount} Yen?`
        if (isRestart) {
            message = `Are you sure you want to restart the payout for ${order.payoutAmount} Yen?`
        }
        if (window.confirm(message)) {
            try {
                setLoading(true);
                await sendPayout(order)
                const tempOrder = order
                order.payoutInitiated = true
                order.payoutInitiatedAt = firestore.Timestamp.now()
                setOrder({ ...tempOrder })
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error)
            }
        }
    }

    const getOrderData = async (order) => {
        try {
            setLoading(true)
            const brandsData = await getBrands();
            const orderDetails = await getOrderDetails(order)
            setOrder(orderDetails)
            setBrands(brandsData)
            setLoading(false)
        } catch (error) {
            setLoading(false);
            alert(error)
        }
    }

    useEffect(() => {
        if (params && params.state && params.state.order) {
            const order = params.state.order
            getOrderData(order)
        }
    }, [])

    const renderPayoutButton = () => {
        return order.payoutStatus ?
            (order.payoutStatus == PayoutStatuses.canceled || order.payoutStatus == PayoutStatuses.failed) ?
                <button onClick={() => payout(true)} className="btn btn-primary btn-block btn-flat">
                    {`Start Payout ${order.payoutAmount} Yen again`}
                </button>
                : <div></div>
            :
            <button onClick={() => payout(false)} className="btn btn-primary btn-block btn-flat">
                {`Payout ${order.payoutAmount} Yen`}
            </button>
    }

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        View Payout Details
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Payouts</a></li>
                        <li className="active">View Payout Details</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            {(order && order.id) ?
                                <div className="box box-primary">
                                    <div className="box-header">
                                        <h3>Surfboard Details</h3>
                                        <p><b>Order Id - {order.id}</b></p>
                                        <p><span><b>Brand - </b></span>
                                            {order.surfboard.brand === FirestoreDatabaseIds.othersBrandId ?
                                                order.surfboard.otherBrandName :
                                                getValuesBasedOnIdFromArray(brands, order.surfboard.brand)}
                                        </p>
                                        <p><span><b>Selling Price - </b></span>{order.sellingPrice}</p>
                                        <p><span><b>Stripe Fee - </b></span>{order.stripeFeeAmount}</p>
                                        <p><span><b>Surfrack Fee - </b></span>{order.surfrackFeeAmount}</p>
                                        <p><b>Payout Amount - {order.payoutAmount}</b></p>

                                        <p><span><b>Order Created At - </b></span>{
                                            new Date(order.createdAt.seconds * 1000).toString()
                                        }</p>
                                        <p><span><b>Order Approved At - </b></span>{
                                            (order.orderApprovedAt && order.orderApprovedAt.seconds) ?
                                                new Date(order.orderApprovedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Confirmed At - </b></span>{
                                            (order.orderConfirmedAt && order.orderConfirmedAt.seconds) ?
                                                new Date(order.orderConfirmedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Payment Done At - </b></span>{
                                            (order.paymentDoneAt && order.paymentDoneAt.seconds) ?
                                                new Date(order.paymentDoneAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Shipped At - </b></span>{
                                            (order.orderShippedAt && order.orderShippedAt.seconds) ?
                                                new Date(order.orderShippedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Delivered At - </b></span>{
                                            (order.orderDeliveredAt && order.orderDeliveredAt.seconds) ?
                                                new Date(order.orderDeliveredAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Cancelled At - </b></span>{
                                            (order.orderCancelledAt && order.orderCancelledAt.seconds) ?
                                                new Date(order.orderCancelledAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>

                                        <img src={order.surfboard.frontImage} alt="Surfboard Image" />
                                    </div>

                                    <div className="box-header">
                                        <h3>Seller Details</h3>

                                        <img src={order.seller.profileImage} width={100} height={100} alt="Seller Image" />
                                        <p><span><b>Name - </b></span>{order.seller.name}</p>
                                        <p><span><b>Username - </b></span>{order.seller.username}</p>
                                        <p><span><b>Email - </b></span>{order.seller.email}</p>
                                        <p><span><b>Phone - </b></span>{order.seller.phoneNumber}</p>
                                    </div>

                                    <div className="box-header">
                                        <h3>Buyer Details</h3>

                                        <img src={order.buyer.profileImage} width={100} height={100} alt="Buyer Image" />
                                        <p><span><b>Name - </b></span>{order.buyer.name}</p>
                                        <p><span><b>Username - </b></span>{order.buyer.username}</p>
                                        <p><span><b>Email - </b></span>{order.buyer.email}</p>
                                        <p><span><b>Phone - </b></span>{order.buyer.phoneNumber}</p>
                                    </div>

                                    {order.payoutStatus ?
                                        <div className="box-footer">
                                            <span><b>Payout Status  - </b></span>
                                            {order.payoutStatus}
                                            <br />
                                            <span><b>Payout Initiated  - </b></span>
                                            {calcTimeSpent(order.payoutInitiatedAt.seconds)}
                                        </div>
                                        : <div />
                                    }
                                    {!isNullOrEmpty(order.payoutFailureCode) ?
                                        <div className="box-footer">
                                            <span><b>Payout Failure Code  - </b></span>
                                            {order.payoutFailureCode}
                                            <br />
                                            <span><b>Payout Failure Message  - </b></span>
                                            {order.payoutFailureMessage}
                                        </div>
                                        : <div />
                                    }
                                    <div className="box-footer">
                                        {loading ?
                                            <LoadingComponent />
                                            :
                                            renderPayoutButton()
                                        }
                                    </div>
                                </div>
                                :
                                <div className="box box-primary">
                                    <LoadingComponent />
                                </div>
                            }
                        </div>
                    </div>
                </section >
            </div >

            <FooterComponent />
        </div >
    );
}

export default PayoutDetailPage;