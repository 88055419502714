import { hitGetApi, hitPostApiWithCustomHeaders } from '../api/ApiHelper';
import { StripeConstants } from '../helpers/Constants';
import { markPayoutInitiated, updatePayoutStatus } from './OrdersService';
import { getConnectedAccountIdOfUser } from './UserService';

export const sendPayout = async (order) => {
    try {
        const sellerConnectedAccountId = await getConnectedAccountIdOfUser(order.sellerId);
        const params = {
            amount: order.payoutAmount,
            currency: StripeConstants.currency,
        }
        const customHeaders = {
            "Stripe-Account": sellerConnectedAccountId
        }
        const jsonResponse = await hitPostApiWithCustomHeaders(StripeConstants.sendPayout, params, customHeaders)
        if (jsonResponse.hasOwnProperty("error")) {
            const title = jsonResponse.error.type
            const message = jsonResponse.error.message
            throw title + ' ' + message
        } else {
            // Mark payout initiated
            const payoutId = jsonResponse.id
            const payoutStatus = jsonResponse.status
            await markPayoutInitiated(order.id, payoutId, payoutStatus)
            return true
        }
    } catch (error) {
        throw error
    }
}

export const checkPayoutStatus = async (orderId, payoutId, currentPayoutStatus) => {
    try {
        const jsonResponse = await hitGetApi(StripeConstants.retrievePayout + payoutId)
        if (jsonResponse.hasOwnProperty("error")) {
            const title = jsonResponse.error.type
            const message = jsonResponse.error.message
            throw title + ' ' + message
        } else {
            if (currentPayoutStatus != jsonResponse.status) {
                // Status changed
                const newPayoutStatus = jsonResponse.status
                const payoutFailureCode = jsonResponse.failure_code
                const payoutFailureMessage = jsonResponse.failure_message
                await updatePayoutStatus(orderId, newPayoutStatus, payoutFailureCode, payoutFailureMessage)
            }
            return true
        }
    } catch (error) {
        throw error
    }
}