import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as firestore from 'firebase/firestore';
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { FirestoreDatabaseIds, ORDER_STATUSES, ORDER_STATUSES_NAMES } from "../helpers/Constants";
import { getValuesBasedOnIdFromArray } from "../helpers/HelperFunctions";
import { getBrands } from "../service/BrandsService";
import { getOrderDetails, updateOrderStatus } from "../service/OrdersService";

const OrderDetailPage = (props) => {
    const [loading, setLoading] = useState(false)
    const params = useLocation()
    const [order, setOrder] = useState(null)
    const [brands, setBrands] = useState([])

    const markOrderDelivered = async () => {
        if (window.confirm(`Are you sure you want to mark ${order.id} as Delivered?`)) {
            try {
                setLoading(true);
                await updateOrderStatus(order.id, ORDER_STATUSES.DELIVERED)
                const tempOrder = order
                order.orderStatus = ORDER_STATUSES.DELIVERED
                order.updatedAt = firestore.Timestamp.now()
                setOrder({ ...tempOrder })
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error)
            }
        }
    }

    const getOrderData = async (order) => {
        try {
            setLoading(true)
            const brandsData = await getBrands();
            const orderDetails = await getOrderDetails(order)
            setOrder(orderDetails)
            setBrands(brandsData)
            setLoading(false)
        } catch (error) {
            setLoading(false);
            alert(error)
        }
    }

    useEffect(() => {
        if (params && params.state && params.state.order) {
            const order = params.state.order
            getOrderData(order)
        }
    }, [])

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        View Order Details
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Orders</a></li>
                        <li className="active">View Order Details</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            {(order && order.id) ?
                                <div className="box box-primary">
                                    <div className="box-header">
                                        <h3>Surfboard Details</h3>
                                        <p><b>Order Id - {order.id}</b></p>
                                        <p><span><b>Order Status - </b></span>{ORDER_STATUSES_NAMES[order.orderStatus]}</p>
                                        <p><span><b>Brand - </b></span>
                                            {order.surfboard.brand === FirestoreDatabaseIds.othersBrandId ?
                                                order.surfboard.otherBrandName :
                                                getValuesBasedOnIdFromArray(brands, order.surfboard.brand)}
                                        </p>
                                        <p><span><b>Selling Price - </b></span>{order.sellingPrice}</p>
                                        <p><span><b>Stripe Fee - </b></span>{order.stripeFeeAmount}</p>
                                        <p><span><b>Surfrack Fee - </b></span>{order.surfrackFeeAmount}</p>
                                        <p><b>Payout Amount - {order.payoutAmount}</b></p>

                                        <p><span><b>Order Created At - </b></span>{
                                            new Date(order.createdAt.seconds * 1000).toString()
                                        }</p>
                                        <p><span><b>Order Approved At - </b></span>{
                                            (order.orderApprovedAt && order.orderApprovedAt.seconds) ?
                                                new Date(order.orderApprovedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Confirmed At - </b></span>{
                                            (order.orderConfirmedAt && order.orderConfirmedAt.seconds) ?
                                                new Date(order.orderConfirmedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Payment Done At - </b></span>{
                                            (order.paymentDoneAt && order.paymentDoneAt.seconds) ?
                                                new Date(order.paymentDoneAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Shipped At - </b></span>{
                                            (order.orderShippedAt && order.orderShippedAt.seconds) ?
                                                new Date(order.orderShippedAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Delivered At - </b></span>{
                                            (order.orderDeliveredAt && order.orderDeliveredAt.seconds) ?
                                                new Date(order.orderDeliveredAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>
                                        <p><span><b>Order Cancelled At - </b></span>{
                                            (order.orderCancelledAt && order.orderCancelledAt.seconds) ?
                                                new Date(order.orderCancelledAt.seconds * 1000).toString()
                                                : "N/A"
                                        }</p>

                                        <img src={order.surfboard.frontImage} alt="Surfboard Image" />
                                    </div>

                                    <div className="box-header">
                                        <h3>Seller Details</h3>

                                        <img src={order.seller.profileImage} width={100} height={100} alt="Seller Image" />
                                        <p><span><b>Name - </b></span>{order.seller.name}</p>
                                        <p><span><b>Username - </b></span>{order.seller.username}</p>
                                        <p><span><b>Email - </b></span>{order.seller.email}</p>
                                        <p><span><b>Phone - </b></span>{order.seller.phoneNumber}</p>
                                    </div>

                                    <div className="box-header">
                                        <h3>Buyer Details</h3>

                                        <img src={order.buyer.profileImage} width={100} height={100} alt="Buyer Image" />
                                        <p><span><b>Name - </b></span>{order.buyer.name}</p>
                                        <p><span><b>Username - </b></span>{order.buyer.username}</p>
                                        <p><span><b>Email - </b></span>{order.buyer.email}</p>
                                        <p><span><b>Phone - </b></span>{order.buyer.phoneNumber}</p>
                                    </div>

                                    <div className="box-footer">
                                        {loading ?
                                            <LoadingComponent />
                                            :
                                            order.orderStatus === ORDER_STATUSES.SHIPPED ?
                                                <button onClick={markOrderDelivered} className="btn btn-primary btn-block btn-flat">
                                                    Mark as Delivered
                                                </button>
                                                : <div />
                                        }
                                    </div>
                                </div>
                                :
                                <div className="box box-primary">
                                    <LoadingComponent />
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default OrderDetailPage;