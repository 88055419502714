// Initialize Firebase
import firebase from './Config';
import { getAuth, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const auth = getAuth();
const functions = getFunctions();

export const authLoginWithEmailAndPassword = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // User logged in successfully
            const loggedInUser = userCredential.user;
            return loggedInUser;
        })
        .catch((error) => {
            throw error;
        });
}

export const authCheckIfUserLoggedIn = (callback) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
        if (callback) {
            callback(user);
        }
        unsubscribe();
    });
}

export const authLogoutUser = async () => {
    return signOut(auth).then(() => {
        // Sign-out successful.
        return true;
    }).catch((error) => {
        // An error occurred.
        throw error
    });
}

export const authUpdatePassword = async (newPassword) => {
    const auth = getAuth();
    const user = auth.currentUser;

    return updatePassword(user, newPassword).then(() => {
        // Update successful.
        return true;
    }).catch((error) => {
        // An error ocurred
        throw error
    });
}

export const deleteUserFromAuth = async (userId) => {
    const deleteAuthUser = httpsCallable(functions, 'deleteAuthUser');
    return deleteAuthUser({ userId: userId })
        .then((result) => {
            return result
        }).catch((error) => {
            throw error
        })
}