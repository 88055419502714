import React from "react";

const NotFoundPage = (props) => {
    return (
        <div className="skin-blue">
            <section className="content">
                <div className="error-page">
                    <h2 className="headline text-yellow"> 404</h2>
                    <div className="error-content">
                        <h3><i className="fa fa-warning text-yellow"></i> Oops! Page not found.</h3>
                        <p>
                            We could not find the page you were looking for.
                            You may <a href='/'>return to dashboard</a>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NotFoundPage;