import React, { useState } from "react";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { isNullOrEmpty } from "../helpers/HelperFunctions";
import { updatePassword } from "../service/UserService";

const ChangePasswordPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const updatePasswordPressed = async () => {
        if (isNullOrEmpty(newPassword)) {
            setNewPasswordError("Please enter New Password");
        } else if (isNullOrEmpty(confirmPassword)) {
            setConfirmPasswordError("Please re-enter New Password");
        } else if (newPassword !== confirmPassword) {
            setConfirmPasswordError("Password does not match");
        } else {
            try {
                setLoading(true);
                await updatePassword(newPassword);
                setNewPassword("");
                setConfirmPassword("");
                setLoading(false);
                alert("Password Updated successfully");
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    }

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Change Password
                        <small>Change Your Password Here</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Change Password</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header">
                                    <h3 className="box-title">Fill the form below</h3>
                                </div>

                                <div className="box-body">
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" className="form-control"
                                            placeholder="Staet Name in English"
                                            onChange={(event) => {
                                                setNewPassword(event.target.value);
                                                setNewPasswordError('');
                                            }}
                                            value={newPassword} />
                                        <p className="error-text">{newPasswordError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" className="form-control"
                                            placeholder="Staet Name in Japanese"
                                            onChange={(event) => {
                                                setConfirmPassword(event.target.value);
                                                setConfirmPasswordError('');
                                            }}
                                            value={confirmPassword} />
                                        <p className="error-text">{confirmPasswordError}</p>
                                    </div>
                                </div>

                                <div className="box-footer">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <button onClick={updatePasswordPressed} className="btn btn-primary btn-block btn-flat">
                                            Change Password
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default ChangePasswordPage;