import React, { useState } from "react";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { isNullOrEmpty } from "../helpers/HelperFunctions";
// import { addCity } from "../service/LocationsService";

const AddCityPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [cityName, setCityName] = useState("");
    const [cityNameError, setCityNameError] = useState("");

    const addCityPressed = async () => {
        if (isNullOrEmpty(cityName)) {
            setCityNameError("Please enter City name");
        } else {
            /* try {
                setLoading(true);
                const success = await addCity(cityName);
                setLoading(false);
                alert("City added successfully");
                setCityName("");
            } catch (error) {
                setLoading(false);
                alert(error);
            } */
        }
    }

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Add New City
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Cities</a></li>
                        <li className="active">Add New City</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header">
                                    <h3 className="box-title">Fill the form below</h3>
                                </div>

                                <div className="box-body">
                                    <div className="form-group">
                                        {/* Hard coded value for now */}
                                        <label for="exampleInputEmail1">Country Name</label>
                                        <select className="form-control">
                                            <option>Japan</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        {/* Hard coded value for now */}
                                        <label for="exampleInputEmail1">State Name</label>
                                        <select className="form-control">
                                            <option>Common</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">City Name</label>
                                        <input type="text" className="form-control"
                                            placeholder="Enter City Name"
                                            onChange={(event) => {
                                                setCityName(event.target.value);
                                                setCityNameError('');
                                            }}
                                            value={cityName} />
                                        <p className="error-text">{cityNameError}</p>
                                    </div>
                                </div>

                                <div className="box-footer">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <button onClick={addCityPressed} className="btn btn-primary btn-block btn-flat">
                                            Add City
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default AddCityPage;