import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { pageNames } from "../helpers/Constants";
import { getBrands } from "../service/BrandsService";

const BrandsPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);

    const navigate = useNavigate();

    const addBrand = () => {
        navigate(pageNames.addBrand);
    }

    const edit = (brand) => {
        navigate(pageNames.addBrand, {
            state: {
                isEdit: true,
                brandToEdit: brand,
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const brandsData = await getBrands();
                setBrands(brandsData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Brands
                        <small>View All Brands</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Brands</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <button style={{ marginTop: 20, marginBottom: 20, marginLeft: 10, }}
                                    onClick={addBrand}>
                                    Add Brand
                                </button>
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Brand Name - EN</th>
                                                    <th>Brand Name - JA</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {brands.map((brand, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{brand.nameEN}</td>
                                                        <td>{brand.nameJA}</td>
                                                        <td><span className="label label-success">Active</span></td>
                                                        <td>
                                                            <button onClick={() => edit(brand)} className="btn btn-primary">
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default BrandsPage;