import React from "react";

const LoadingComponent = (props) => {
    return (
        <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, }}>Please wait...</p>
        </div>
    );
}

export default LoadingComponent;