import { addCountryToFirestore, addStateToFirestore, getAllCountries, updateStateToFirestore } from '../firebaseDao/LocationsDao';

export const addCountryService = async () => {
    try {
        await addCountryToFirestore();
    } catch (error) {
        throw error
    }
}

export const addState = async (nameEN, nameJA, stateIndex) => {
    try {
        await addStateToFirestore(nameEN, nameJA, stateIndex);
    } catch (error) {
        throw error
    }
}

export const updateState = async (stateId, nameEN, nameJA, stateIndex) => {
    try {
        await updateStateToFirestore(stateId, nameEN, nameJA, stateIndex);
    } catch (error) {
        throw error
    }
}

/* export const addCity = async (name) => {
    try {
        await addCityToFirestore(name);
    } catch (error) {
        throw error
    }
} */

export const getStates = async () => {
    try {
        const allStates = [];
        const countries = await getAllCountries();

        if(countries && countries.length > 0) {
            countries[0].states.forEach((state, index) => {
                const stateObj = {
                    countryId: countries[0].id,
                    countryNameEN: countries[0].nameEN,
                    countryNameJA: countries[0].nameJA,
                    stateId: state.id,
                    stateNameEN: state.nameEN,
                    stateNameJA: state.nameJA,
                    stateIndex: state.stateIndex,
                }
                allStates.push(stateObj);
            })
        }

        return allStates;
    } catch (error) {
        throw error
    }
}

/* export const getCities = async () => {
    try {
        const allCities = [];
        const countries = await getAllCountries();
        const cities = await getAllCities();

        cities.forEach((city, index) => {
            const cityObj = {
                ...city,
                countryId: countries[0].id,
                countryName: countries[0].name,
                stateId: countries[0].states[0].id,
                stateName: countries[0].states[0].name,
            }
            allCities.push(cityObj);
        })

        return allCities;
    } catch (error) {
        throw error
    }
} */