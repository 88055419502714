import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export const sendPushNotificationToMultipleTokens = async (title, body, customData) => {
    const sendAdminPushToTopic = httpsCallable(functions, 'sendAdminPushToTopic');
    sendAdminPushToTopic({
        title,
        body,
        customData
    })
        .then((result) => {
            console.log('result ' + JSON.stringify(result));
            return result
        }).catch((error) => {
            console.log('error ' + JSON.stringify(error));
            throw error
        });
}