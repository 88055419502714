import React from "react";
import { useNavigate } from "react-router-dom";
import { pageNames } from '../helpers/Constants';
import { logoutUser } from '../service/UserService';

const LeftMenuComponent = (props) => {

    const navigate = useNavigate();

    const doLogout = async () => {
        const success = await logoutUser();
        if (success) {
            navigate(pageNames.loginPage, {
                replace: true,
            });
        } else {
            alert("Could not logout");
        }
    }

    return (
        <aside className="main-sidebar">
            {/*  sidebar: style can be found in sidebar.less */}
            <section className="sidebar">
                {/*  Sidebar user panel */}
                <div className="user-panel">
                    <div className="pull-left image">
                        <img src="dist/img/user2-160x160.jpg" className="img-circle" alt="User Image" />
                    </div>
                    <div className="pull-left info">
                        <p>Admin</p>

                        <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
                    </div>
                </div>
                {/*  /.search form */}
                {/*  sidebar menu: : style can be found in sidebar.less */}
                <ul className="sidebar-menu">
                    <li className="header">MAIN NAVIGATION</li>
                    <li>
                        <a href={pageNames.home}>
                            <i className="fa fa-dashboard"></i>
                            <span>Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.payouts}>
                            <i className="fa fa-globe"></i>
                            <span>Payouts</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.orders}>
                            <i className="fa fa-globe"></i>
                            <span>Orders</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.users}>
                            <i className="fa fa-globe"></i>
                            <span>Users</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.surfboards}>
                            <i className="fa fa-globe"></i>
                            <span>Surfboards</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.states}>
                            <i className="fa fa-globe"></i>
                            <span>States</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.brands}>
                            <i className="fa fa-th"></i>
                            <span>Brands</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.sendPush}>
                            <i className="fa fa-edit"></i>
                            <span>Send Push</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.deactivatedUsers}>
                            <i className="fa fa-flag"></i>
                            <span>Deactivated Users</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.reportedUsers}>
                            <i className="fa fa-flag"></i>
                            <span>Reported Users</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.changePassword}>
                            <i className="fa fa-key"></i>
                            <span>Change Password</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.cities}>
                            <i className="fa fa-globe"></i>
                            <span>Cities</span>
                        </a>
                    </li>
                    <li>
                        <a href={pageNames.suggestions}>
                            <i className="fa fa-edit"></i>
                            <span>Suggestions</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={doLogout}>
                            <i className="fa fa-sign-out"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </section>
            {/*  /.sidebar */}
        </aside>
    );
}

export default LeftMenuComponent;