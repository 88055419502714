// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAoRBuT21ITsFrc5t8EahLX_fInLEs4-2Y",
  authDomain: "surf-rack-mvp.firebaseapp.com",
  projectId: "surf-rack-mvp",
  storageBucket: "surf-rack-mvp.appspot.com",
  messagingSenderId: "305553472419",
  appId: "1:305553472419:web:c2d339ab6b62e9f9563816",
  measurementId: "G-BGE3T58PNC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;