import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as firestore from 'firebase/firestore';
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { UserTypes, pageNames } from "../helpers/Constants";
import { getSurfboardsByUserId } from "../service/SurfboardService";

const UserDetailPage = (props) => {
    const params = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [surfboards, setSurfboards] = useState([])

    const getUserData = async (user) => {
        try {
            setLoading(true)
            const surfboards = await getSurfboardsByUserId(user.id);
            setSurfboards(surfboards)
            setUser(user)
            setLoading(false)
        } catch (error) {
            setLoading(false);
            alert(error)
        }
    }

    const onViewDetails = (surfboard) => {
        navigate(pageNames.surfboardDetail, {
            state: {
                surfboard: surfboard
            }
        });
    }

    useEffect(() => {
        if (params && params.state && params.state.user) {
            const user = params.state.user
            getUserData(user)
        }
    }, [])

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        View User Details
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Users</a></li>
                        <li className="active">View User Details</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            {(user && user.id) ?
                                <div className="box box-primary">
                                    <div className="box-header">
                                        <h3>User Details</h3>
                                        <p><span><b>Profile Photo - </b></span><img src={user.profileImage} width={100} height={100} alt="User Image" /></p>
                                        <p><b>User Id - {user.id}</b></p>
                                        <p><span><b>Name - </b></span>{user.name}</p>
                                        <p><span><b>Email - </b></span>{user.email}</p>
                                        <p><span><b>Phone Number - </b></span>{user.phoneNumber}</p>
                                        <p><span><b>Username - </b></span>{user.username}</p>
                                        <p><span><b>User Type - </b></span>{user.userType === UserTypes.USER ? 'User' : 'Shop'}</p>
                                        <p><span><b>About Me - </b></span>{user.aboutMe}</p>
                                        <p><span><b>Signed up on - </b></span>{new Date(user.createdAt.seconds * 1000).toString()}</p>
                                        <p><span><b>Surfboards Posted - </b></span>{user.surfboards ? user.surfboards.length : '0'}</p>
                                        <p><span><b>Number of Followers - </b></span>{user.followers ? user.followers.length : '0'}</p>
                                        <p><span><b>Number of Following - </b></span>{user.following ? user.following.length : '0'}</p>

                                        <h3>Address</h3>
                                        <p><span><b>Address - </b></span>{(user.address && user.address.address) ? user.address.address : ""}</p>
                                        <p><span><b>City - </b></span>{(user.address && user.address.city) ? user.address.city : ""}</p>
                                        <p><span><b>State - </b></span>{(user.address && user.address.state) ? user.address.state : ""}</p>
                                        <p><span><b>Zip Code - </b></span>{(user.address && user.address.zipCode) ? user.address.zipCode.postalCodePartA + '-' + user.address.zipCode.postalCodePartB : ""}</p>
                                    </div>

                                    {user.userType === UserTypes.BUSINESS ?
                                        <div className="box-header">
                                            <h3>Shop Details</h3>
                                            <p><span><b>Email - </b></span>{(user.shop && user.shop.email) ? user.shop.email : ""}</p>
                                            <p><span><b>Phone Number - </b></span>{(user.shop && user.shop.phone) ? user.shop.phone : ""}</p>
                                            <p><span><b>Services - </b></span>{(user.shop && user.shop.services) ? user.shop.services : ""}</p>
                                            <p><span><b>Website - </b></span>{(user.shop && user.shop.website) ? user.shop.website : ""}</p>
                                        </div>
                                        : <div />
                                    }

                                    {(surfboards && surfboards.length > 0) ?
                                        <div className="box-header">
                                            <h3>Surfboards</h3>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Price</th>
                                                        <th>Brand</th>
                                                        <th>Uploaded On</th>
                                                        <th>Is Available</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {surfboards.map((surfboard, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{surfboard.sellingPrice}</td>
                                                            <td>{surfboard.brandName}</td>
                                                            <td>{new Date(surfboard.createdAt.seconds * 1000).toString()}</td>
                                                            <td>{surfboard.isAvailable ? "Yes" : "No"}</td>
                                                            <td>
                                                                <button onClick={() => onViewDetails(surfboard)} className="btn btn-primary">
                                                                    View Details
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        : <div />
                                    }
                                </div>
                                :
                                <div className="box box-primary">
                                    <LoadingComponent />
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default UserDetailPage;