import { getFirestore, getDocs, collection, query, where, doc, getDoc, setDoc } from "firebase/firestore"

const db = getFirestore();
const usersCollectionName = "users";

export const getAllDeactivatedUsers = async () => {
    try {
        const usersCollection = collection(db, usersCollectionName);
        const q = query(usersCollection, where("isDeactivated", "==", true));
        const usersSnapshot = await getDocs(q);
        const usersList = usersSnapshot.docs.map(doc => doc.data());
        return usersList;
    } catch (e) {
        throw e
    }
}

export const firestoreUpdateUser = async (userId, data) => {
    try {
        await setDoc(doc(db, usersCollectionName, userId), data, { merge: true });
    } catch (e) {
        throw e
    }
}

export const getUserDetailsById = async (userId) => {
    try {
        const userRef = doc(db, usersCollectionName, userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            return userSnap.data();
        } else {
            throw "No such user"
        }
    } catch (e) {
        throw e
    }
}

export const getAllUsers = async () => {
    try {
        const usersCollection = collection(db, usersCollectionName);
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => doc.data());
        return usersList;
    } catch (e) {
        throw e
    }
}