import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getReportedUsers } from "../service/ReportsService";
import { pageNames } from "../helpers/Constants";

const ReportedUsersPage = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [reportedUsers, setReportedUsers] = useState([]);

    const onViewDetails = (user) => {
        navigate(pageNames.userDetail, {
            state: {
                user: user
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const reportedUsersData = await getReportedUsers();
                setReportedUsers(reportedUsersData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Reported Users
                        <small>View All Reported Users</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Reported Users</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Profile Photo</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Reason</th>
                                                    <th>Reported By</th>
                                                    <th>Reported At</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportedUsers.map((reportedUser, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><img src={reportedUser.reportedUserDetails.profileImage} alt="User Image" height="100" width="100" /></td>
                                                        <td>{reportedUser.reportedUserDetails.name}</td>
                                                        <td>{reportedUser.reportedUserDetails.email}</td>
                                                        <td>{reportedUser.reportedUserDetails.phone}</td>
                                                        <td>{reportedUser.reason}</td>
                                                        <td>{reportedUser.reportedByUserDetails.name}</td>
                                                        <td>{new Date(reportedUser.createdAt.seconds * 1000).toString()}</td>
                                                        <td>
                                                            <button onClick={() => onViewDetails(reportedUser.reportedUserDetails)} className="btn btn-primary">
                                                                View Reported User
                                                            </button>
                                                            <br/><br/>
                                                            <button onClick={() => onViewDetails(reportedUser.reportedByUserDetails)} className="btn btn-primary">
                                                                View Reported By User
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default ReportedUsersPage;