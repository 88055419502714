import { getFirestore, getDocs, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { firestoreAutoId } from "../helpers/HelperFunctions";

const db = getFirestore();
const countriesCollectionName = "countries";
const citiesCollectionName = "cities";

const countryId = "9qAZ500vxl9sWFPBKRhV"

export const addCountryToFirestore = async () => {
    try {
        const newCountryRef = doc(collection(db, countriesCollectionName));
        const data = {
            id: newCountryRef.id,
            nameEN: "Japan",
            nameJA: "日本",
            states: [],
        };
        await setDoc(newCountryRef, data);
    } catch (e) {
        throw e
    }
}

export const addStateToFirestore = async (nameEN, nameJA, stateIndex) => {
    try {
        const state = {
            id: firestoreAutoId(),
            nameEN,
            nameJA,
            stateIndex,
            cities: [],
        }

        // Hard coding for now
        const docRef = doc(db, countriesCollectionName, countryId);
        const docSnap = await getDoc(docRef);
        const currentData = docSnap.data()
        const currentStates = currentData.states
        currentStates.push(state)

        await updateDoc(docRef, {...currentData});
    } catch (e) {
        throw e
    }
}

export const updateStateToFirestore = async (stateId, stateNameEN, stateNameJA, stateIndex) => {
    try {
        // Hard coding for now
        const docRef = doc(db, countriesCollectionName, countryId);
        const docSnap = await getDoc(docRef);
        const currentData = docSnap.data()
        const currentStates = currentData.states
        
        for(let i = 0; i < currentStates.length; ++i) {
            let state = currentStates[i];
            if(state.id === stateId) {
                state.nameEN = stateNameEN
                state.nameJA = stateNameJA
                state.stateIndex = stateIndex
                break;
            }
        }

        await updateDoc(docRef, {...currentData});
    } catch (e) {
        throw e
    }
}

/* export const addCityToFirestore = async (name) => {
    try {
        const newCityRef = doc(collection(db, citiesCollectionName));
        const data = {
            id: newCityRef.id,
            name,
        }
        await setDoc(newCityRef, data);

        // Hard coding for now
        const docRef = doc(db, countriesCollectionName, "C0f9ko2Nlpu3XWC9YWLG");
        const docSnap = await getDoc(docRef);
        const currentData = docSnap.data()
        const currentCities = currentData.states[0].cities
        const cityObj = {
            id: newCityRef.id,
        }
        currentCities.push(cityObj)

        await updateDoc(docRef, {...currentData});
    } catch (e) {
        throw e
    }
} */

export const getAllCountries = async () => {
    try {
        const countriesCollection = collection(db, countriesCollectionName);
        const countriesSnapshot = await getDocs(countriesCollection);
        const countriesList = countriesSnapshot.docs.map(doc => doc.data());
        return countriesList;
    } catch (e) {
        throw e
    }
}

/* export const getAllCities = async () => {
    try {
        const citiesCollection = collection(db, citiesCollectionName);
        // const q = query(citiesCollection, where("stateId", "==", stateId));
        // const citiesSnapshot = await getDocs(q);
        const citiesSnapshot = await getDocs(citiesCollection);
        const citiesList = citiesSnapshot.docs.map(doc => doc.data());
        return citiesList;
    } catch (e) {
        throw e
    }
} */