import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getUsersStats } from "../service/UserService";
import { getSurfboardStats } from "../service/SurfboardService";
import { getOrdersStats } from "../service/OrdersService";
import { pageNames } from "../helpers/Constants";

const HomePage = (props) => {
    const [stats, setStats] = useState({});
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();

    const fetchStats = async () => {
        setLoading(true)
        const userStats = await getUsersStats()
        const surfboardStats = await getSurfboardStats()
        const orderStats = await getOrdersStats()

        setStats({
            userStats, surfboardStats, orderStats
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchStats();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            {/*  Right side column. Contains the navbar and content of the page */}
            <div className="content-wrapper">
                {/*  Content Header (Page header) */}
                <section className="content-header">
                    <h1>
                        Dashboard
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Dashboard</li>
                    </ol>
                </section>

                {isLoading ?
                    <LoadingComponent />
                    :
                    <section className="content">
                        {/* First Row */}
                        {/*  Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-aqua">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.totalUsers : ""}</h3>
                                        <p>Total General Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-green">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.activeUsers : ""}</h3>
                                        <p>Active General Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-yellow">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.deactivatedUsers : ""}</h3>
                                        <p>Deactivated General Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-red">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.deletedUsers : ""}</h3>
                                        <p>Deleted General Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                        </div>{/*  /.row */}

                        {/* Second Row */}
                        {/*  Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-aqua">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.totalShops : ""}</h3>
                                        <p>Total Shop Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-green">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.activeShops : ""}</h3>
                                        <p>Active Shop Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-yellow">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.deactivatedShops : ""}</h3>
                                        <p>Deactivated Shop Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-red">
                                    <div className="inner">
                                        <h3>{(stats && stats.userStats) ? stats.userStats.deletedShops : ""}</h3>
                                        <p>Deleted Shop Accounts</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                        </div>{/*  /.row */}

                        {/* Third Row */}
                        {/*  Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-xs-6 pointer">
                                {/*  small box */}
                                <div className="small-box bg-aqua"
                                    onClick={() => {
                                        navigate(pageNames.surfboards);
                                    }}>
                                    <div className="inner">
                                        <h3>{(stats && stats.surfboardStats) ? stats.surfboardStats.totalSurfboards : ""}</h3>
                                        <p>Total Surfboards</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-green">
                                    <div className="inner">
                                        <h3>{(stats && stats.surfboardStats) ? stats.surfboardStats.availableSurfboards : ""}</h3>
                                        <p>Available Surfboards</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-yellow">
                                    <div className="inner">
                                        <h3>{(stats && stats.surfboardStats) ? stats.surfboardStats.soldSurfboards : ""}</h3>
                                        <p>Sold Surfboards</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-red">
                                    <div className="inner">
                                        <h3>&nbsp;</h3>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                        </div>{/*  /.row */}

                        {/* Fourth Row */}
                        {/*  Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-xs-6 pointer">
                                {/*  small box */}
                                <div className="small-box bg-aqua"
                                    onClick={() => {
                                        navigate(pageNames.orders);
                                    }}>
                                    <div className="inner">
                                        <h3>{(stats && stats.orderStats) ? stats.orderStats.totalOrders : ""}</h3>
                                        <p>Total Orders</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6 pointer">
                                {/*  small box */}
                                <div className="small-box bg-green"
                                    onClick={() => {
                                        navigate(pageNames.payouts);
                                    }}>
                                    <div className="inner">
                                        <h3>{(stats && stats.orderStats) ? stats.orderStats.paymentsDone : ""}</h3>
                                        <p>Payments Done</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-yellow">
                                    <div className="inner">
                                        <h3>{(stats && stats.orderStats) ? stats.orderStats.payoutsDone : ""}</h3>
                                        <p>Payouts Done</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                            <div className="col-lg-3 col-xs-6">
                                {/*  small box */}
                                <div className="small-box bg-red">
                                    <div className="inner">
                                        <h3>&nbsp;</h3>
                                        <p>&nbsp;</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>{/*  ./col */}
                        </div>{/*  /.row */}
                    </section>
                }

            </div>{/*  /.content-wrapper */}

            <FooterComponent />
        </div >
    );
}

export default HomePage;