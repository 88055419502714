import * as firestore from 'firebase/firestore';
import { authCheckIfUserLoggedIn, authLoginWithEmailAndPassword, authLogoutUser, authUpdatePassword, deleteUserFromAuth } from '../firebaseDao/Auth';
import { getUserById } from '../firebaseDao/AdminUsersDao';
import { getAllUsers, getUserDetailsById } from '../firebaseDao/UsersDao';
import { firestoreUpdateUser, getAllDeactivatedUsers } from '../firebaseDao/UsersDao';
import { deleteProfileImage } from '../firebaseDao/StorageDao';
import { UserTypes } from '../helpers/Constants';

export const loginWithEmailAndPassword = async (email, password) => {
    try {
        const loggedInUser = await authLoginWithEmailAndPassword(email, password);

        // Check if logged in user is admin
        const user = await getUserById(loggedInUser.uid);

        return true;
    } catch (error) {
        if (error.code && error.code === 'auth/user-not-found') {
            throw "No such User"
        } else if (error.code && error.code === 'auth/wrong-password') {
            throw "Wrong Password"
        } else {
            throw error
        }
    }
}

export const checkIfUserLoggedIn = (callback) => {
    authCheckIfUserLoggedIn(async (loggedInUser) => {
        if (callback) {
            // get logged in user's details
            if (loggedInUser) {
                // loggedInUser.uid

                callback(true);
            } else {
                callback(null);
            }
        }
    });
}

export const logoutUser = async () => {
    try {
        const success = await authLogoutUser();
        return success;
    } catch (error) {
        throw error
    }
}

export const updatePassword = async (newPassword) => {
    try {
        await authUpdatePassword(newPassword);
    } catch (error) {
        throw error
    }
}

export const getDeactivatedUsers = async () => {
    try {
        const allDeactivatedUsers = await getAllDeactivatedUsers();
        const onlyDeactivatedUsers = []
        if (allDeactivatedUsers && allDeactivatedUsers.length > 0) {
            for (let i = 0; i < allDeactivatedUsers.length; ++i) {
                const user = allDeactivatedUsers[i]
                if (!user.isDeleted) {
                    onlyDeactivatedUsers.push(user)
                }
            }
        }
        return onlyDeactivatedUsers
    } catch (error) {
        throw error
    }
}

export const deleteUser = async (user) => {
    try {
        // Delete profile image of the user
        await deleteProfileImage(user.profileImage)
        let newData = {
            isDeleted: true,
            deletedAt: firestore.Timestamp.now(),
            aboutMe: null,
            address: null,
            badgeCount: 0,
            email: null,
            fcmTokens: [],
            followers: [],
            following: [],
            likes: [],
            bookmarks: [],
            name: "Deleted User",
            phoneNumber: null,
            username: null,
            profileImage: null,
        }
        if (user.shop) {
            newData.shop = null
        }
        await firestoreUpdateUser(user.id, newData);
        const response = await deleteUserFromAuth(user.id);
        if (response.data.status) {
            return true
        } else {
            throw response.data.message
        }
    } catch (error) {
        throw error
    }
}

export const getConnectedAccountIdOfUser = async (userId) => {
    try {
        const user = await getUserDetailsById(userId);
        if (user && user.stripe && user.stripe.connectedAccount && user.stripe.connectedAccount.id) {
            return user.stripe.connectedAccount.id
        } else {
            throw "No connected account id found"
        }
    } catch (error) {
        throw error
    }
}

export const getUsersStats = async () => {
    try {
        const allUsers = await getAllUsers();
        const activeUsers = []
        const deactivatedUsers = []
        const deletedUsers = []

        const activeShops = []
        const deactivatedShops = []
        const deletedShops = []

        let totalUsers = 0
        let totalShops = 0

        const result = {}

        if (allUsers && allUsers.length > 0) {
            for (let i = 0; i < allUsers.length; ++i) {
                const user = allUsers[i]
                if (user.userType === UserTypes.USER) {
                    ++totalUsers
                    if (user.isDeleted) {
                        deletedUsers.push(user)
                    } else if (user.isDeactivated) {
                        deactivatedUsers.push(user)
                    } else {
                        activeUsers.push(user)
                    }
                } else {
                    ++totalShops
                    if (user.isDeleted) {
                        deletedShops.push(user)
                    } else if (user.isDeactivated) {
                        deactivatedShops.push(user)
                    } else {
                        activeShops.push(user)
                    }
                }
            }
        }

        result.totalUsers = totalUsers + ""
        result.deletedUsers = deletedUsers.length + ""
        result.deactivatedUsers = deactivatedUsers.length + ""
        result.activeUsers = activeUsers.length + ""

        result.totalShops = totalShops + ""
        result.deletedShops = deletedShops.length + ""
        result.deactivatedShops = deactivatedShops.length + ""
        result.activeShops = activeShops.length + ""

        return result
    } catch (error) {
        throw error
    }
}

export const getUsers = async () => {
    try {
        const users = await getAllUsers();
        return users;
    } catch (error) {
        throw error
    }
}