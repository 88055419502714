import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { pageNames } from "../helpers/Constants";
// import { getCities } from "../service/LocationsService";

const CitiesPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);

    const navigate = useNavigate();

    const addCity = () => {
        navigate(pageNames.addCity);
    }

    useEffect(() => {
        const fetchData = async () => {
            /* try {
                setLoading(true);
                const citiesData = await getCities();
                setCities(citiesData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            } */
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Cities
                        <small>View All Cities</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Cities</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <button style={{ marginTop: 20, marginBottom: 20, marginLeft: 10, }}
                                    onClick={addCity}>
                                    Add City
                                </button>
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Country</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cities.map((city, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{city.countryName}</td>
                                                        <td>{city.stateName}</td>
                                                        <td>{city.name}</td>
                                                        <td><span className="label label-success">Active</span></td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default CitiesPage;