import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { isNullOrEmpty } from "../helpers/HelperFunctions";
import { addBrand, updateBrand } from "../service/BrandsService";

const AddBrandPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [brandNameEN, setBrandNameEN] = useState("");
    const [brandNameJA, setBrandNameJA] = useState("");
    const [brandNameENError, setBrandNameENError] = useState("");
    const [brandNameJAError, setBrandNameJAError] = useState("");
    const [brandToEditId, setBrandToEditId] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const params = useLocation()

    const addBrandPressed = async () => {
        const strBrandNameEn = brandNameEN.trim();
        const strBrandNameJA = brandNameJA.trim();
        if (isNullOrEmpty(strBrandNameEn)) {
            setBrandNameENError("Please enter Brand name in English");
        } else if (isNullOrEmpty(strBrandNameJA)) {
            setBrandNameJAError("Please enter Brand name in Japanese");
        } else {
            try {
                setLoading(true);
                let message = ""
                if (isEdit) {
                    await updateBrand(brandToEditId, strBrandNameEn, strBrandNameJA);
                    message = "Brand updated successfully"
                } else {
                    await addBrand(strBrandNameEn, strBrandNameJA);
                    message = "Brand added successfully"
                    setBrandNameEN("");
                    setBrandNameJA("");
                }
                setLoading(false);
                alert(message);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
    }

    useEffect(() => {
        if (params && params.state && params.state.isEdit) {
            setIsEdit(true);
            const brandToEdit = params.state.brandToEdit
            setBrandToEditId(brandToEdit.id);
            setBrandNameEN(brandToEdit.nameEN);
            setBrandNameJA(brandToEdit.nameJA);
        }
    }, [])

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        {isEdit ? "Update Brand" : "Add New Brand"}
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Brands</a></li>
                        <li className="active">Add/Edit New Brand</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box box-primary">
                                <div className="box-header">
                                    <h3 className="box-title">Fill the form below</h3>
                                </div>

                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Brand Name - EN</label>
                                        <input type="text" className="form-control"
                                            placeholder="State Name in English"
                                            onChange={(event) => {
                                                setBrandNameEN(event.target.value);
                                                setBrandNameENError('');
                                            }}
                                            value={brandNameEN} />
                                        <p className="error-text">{brandNameENError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Brand Name - JA</label>
                                        <input type="text" className="form-control"
                                            placeholder="State Name in Japanese"
                                            onChange={(event) => {
                                                setBrandNameJA(event.target.value);
                                                setBrandNameJAError('');
                                            }}
                                            value={brandNameJA} />
                                        <p className="error-text">{brandNameJAError}</p>
                                    </div>
                                </div>

                                <div className="box-footer">
                                    {loading ?
                                        <LoadingComponent />
                                        :
                                        <button onClick={addBrandPressed} className="btn btn-primary btn-block btn-flat">
                                            {isEdit ? "Update Brand" : "Add Brand"}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default AddBrandPage;