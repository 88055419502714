import React from "react";

const HeaderComponent = (props) => {
    return (
        <header className="main-header">
            {/* Logo */}
            <a href="#" className="logo"><b>Surf Rack</b></a>
            {/*  Header Navbar: style can be found in header.less */}
            <nav className="navbar navbar-static-top" role="navigation">
            </nav>
        </header>
    );
}

export default HeaderComponent;