import React, { useState, useEffect } from "react";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { getAllSuggestions } from "../firebaseDao/SuggestionsDao";

const SuggestionsPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const suggestionsData = await getAllSuggestions();
                setSuggestions(suggestionsData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        }
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Suggestions
                        <small>View All Suggestions</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Suggestions</li>
                    </ol>
                </section>

                <section className="content">
                <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>For</th>
                                                    <th>Suggestion</th>
                                                    <th>Suggested By</th>
                                                    <th>Suggested At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {suggestions.map((suggestion, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{suggestion.fieldName}</td>
                                                        <td>{suggestion.text}</td>
                                                        <td>{suggestion.suggestedBy.firstName + " " + suggestion.suggestedBy.lastName}</td>
                                                        <td>{new Date(suggestion.createdAt.seconds * 1000).toString()}</td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default SuggestionsPage;