import * as firestore from 'firebase/firestore'

export const isNullOrEmpty = (value) => {
    return !value || value.length === 0
}

export const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const firestoreAutoId = () => {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let autoId = ''

    for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length)
        )
    }
    return autoId
}

export const getDateFromSeconds = (seconds) => {
    let date = new Date(seconds * 1000);
    var options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleString("ja-JA", options);
}

export const calcTimeSpent = (createdAtSeconds) => {
    let now = firestore.Timestamp.now();
    let todaySeconds = now.seconds

    let differenceSeconds = todaySeconds - createdAtSeconds;
    let interval = Math.floor(differenceSeconds / 31536000)
    if (interval >= 1) {
        return interval + (interval === 1 ? ' year ago' : ' years ago')
    }
    interval = Math.floor(differenceSeconds / 2592000)
    if (interval >= 1) {
        return interval + (interval === 1 ? ' month ago' : ' months ago')
    }
    interval = Math.floor(differenceSeconds / 86400)
    if (interval >= 1) {
        return interval + (interval === 1 ? ' day ago' : ' days ago')
    }
    interval = Math.floor(differenceSeconds / 3600);
    if (interval >= 1) {
        return interval + (interval === 1 ? ' hour ago' : ' hours ago')
    }
    interval = Math.floor(differenceSeconds / 60);
    if (interval >= 1) {
        return interval + (interval === 1 ? ' minute ago' : ' minutes ago')
    }
    if (Math.floor(differenceSeconds) <= 10) {
        return 'Just Now';
    }
    return Math.floor(differenceSeconds) + ' seconds ago';
}

export const compareOrders = (a, b) => {
    const aSeconds = (a.updatedAt && a.updatedAt.seconds) ? a.updatedAt.seconds : a.createdAt.seconds
    const bSeconds = (b.updatedAt && b.updatedAt.seconds) ? b.updatedAt.seconds : b.createdAt.seconds
    if (aSeconds < bSeconds) {
        return 1;
    }
    if (aSeconds > bSeconds) {
        return -1;
    }
    return 0;
}

export const getValuesBasedOnIdFromArray = (array, id) => {
    if (array && id) {
        let currentItem = null
        for (let i = 0; i < array.length; ++i) {
            let item = array[i];
            if (item.id === id) {
                currentItem = item
                break;
            }
        }
        return currentItem ? currentItem.nameEN : 'N/A'
    } else {
        return 'N/A'
    }
}

export const getLocationBasedOnIdFromArray = (locations, id) => {
    if (locations && id) {
        let currentItem = null
        for (let i = 0; i < locations.length; ++i) {
            let item = locations[i];
            if (item.stateId === id) {
                currentItem = item
                break;
            }
        }
        return currentItem ? currentItem.stateNameEN : "N/A"
    }
    return "N/A"
}

export const getInchesFromFeetInches = (feet, inches) => {
    let allInches = 0
    if (!isNullOrEmpty(feet)) {
        allInches = parseInt(feet) * 12
    }
    if (!isNullOrEmpty(inches)) {
        allInches += parseFloat(inches)
    }
    return allInches
}

export const getFeetInchesFromInches = (allInches) => {
    let feet = 0
    let inches = 0
    feet = Math.floor(allInches / 12)
    inches = allInches % 12
    return { feet, inches }
}

export const getDisplayFeetInchesFromInches = (allInches) => {
    const { feet, inches } = getFeetInchesFromInches(allInches)
    return feet + ' ' + 'feet' + ' ' + inches + ' ' + 'inches'
}

export const getShortDisplayFeetInchesFromInches = (allInches) => {
    const { feet, inches } = getFeetInchesFromInches(allInches)
    return feet + '\'' + inches + '\"'
}

export const getCMsFromInches = (inches) => {
    return inches ? (inches * 12) + "cm" : "N/A"
}

export const getFractionsToDisplay = (numerator, denominator) => {
    const superscript = {
        '0': '⁰',
        '1': '¹',
        '2': '²',
        '3': '³',
        '4': '⁴',
        '5': '⁵',
        '6': '⁶',
        '7': '⁷',
        '8': '⁸',
        '9': '⁹',
    };
    const subscript = {
        '0': '₀',
        '1': '₁',
        '2': '₂',
        '3': '₃',
        '4': '₄',
        '5': '₅',
        '6': '₆',
        '7': '₇',
        '8': '₈',
        '9': '₉',
    }
    let strNumerator = ""
    let strDenominator = ""
    var digits = numerator.toString().split('');
    digits.map((digit) => {
        strNumerator += superscript[digit]
    })
    digits = denominator.toString().split('');
    digits.map((digit) => {
        strDenominator += subscript[digit]
    })
    return isNullOrEmpty(strNumerator) ? '' : (strNumerator + "⁄" + strDenominator)
}

export const getDisplayZipCode = (zipCode) => {
    let postalCode = ""
    if (zipCode.postalCodePartA) {
        postalCode += zipCode.postalCodePartA
    }
    if (zipCode.postalCodePartB) {
        postalCode += '-' + zipCode.postalCodePartB
    }
    return postalCode
}

export const compareStates = (a, b) => {
    return a.stateIndex - b.stateIndex;
}