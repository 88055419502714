import { getAllReportedUsers } from '../firebaseDao/ReportsDao';
import { getUserDetailsById } from '../firebaseDao/UsersDao';

export const getReportedUsers = async () => {
    try {
        const reportsList = await getAllReportedUsers();

        for (let i = 0; i < reportsList.length; ++i) {
            const report = reportsList[i];
            const reportedByUserDetails = await getUserDetailsById(report.reportedByUserId);
            const reportedUserDetails = await getUserDetailsById(report.reportedUserId);
            report.reportedByUserDetails = reportedByUserDetails
            report.reportedUserDetails = reportedUserDetails
        }

        return reportsList;
    } catch (error) {
        throw error
    }
}