import * as firestore from 'firebase/firestore';
import { dbUpdateOrder, getOrders, getOrdersForPayout } from '../firebaseDao/OrdersDao';
import { dbGetSurfboardDetail } from '../firebaseDao/SurfboardsDao';
import { getUserDetailsById } from '../firebaseDao/UsersDao';

export const getPayoutOrders = async () => {
    try {
        return await getOrdersForPayout();
    } catch (error) {
        throw error
    }
}

export const getAllOrders = async () => {
    try {
        return await getOrders();
    } catch (error) {
        throw error
    }
}

export const markPayoutInitiated = async (orderId, payoutId, payoutStatus) => {
    try {
        const timestamp = firestore.Timestamp.now()
        let newData = {
            payoutId,
            payoutStatus,
            payoutFailureCode: null,
            payoutFailureMessage: null,
            payoutInitiatedAt: timestamp,
            updatedAt: timestamp,
        }
        await dbUpdateOrder(orderId, newData);
    } catch (error) {
        throw error
    }
}

export const updatePayoutStatus = async (orderId, payoutStatus, payoutFailureCode, payoutFailureMessage) => {
    try {
        const timestamp = firestore.Timestamp.now()
        let newData = {
            payoutStatus,
            payoutFailureCode,
            payoutFailureMessage,
            updatedAt: timestamp
        }
        await dbUpdateOrder(orderId, newData);
    } catch (error) {
        throw error
    }
}

export const getOrderDetails = async (order) => {
    try {
        const buyer = await getUserDetailsById(order.buyerId)
        const seller = await getUserDetailsById(order.sellerId)
        const surfboard = await dbGetSurfboardDetail(order.surfboardId)

        return {
            ...order,
            buyer,
            seller,
            surfboard,
        }
    } catch (error) {
        throw error
    }
}

export const updateOrderStatus = async (orderId, newStatus) => {
    try {
        const timestamp = firestore.Timestamp.now()
        let newData = {
            orderStatus: newStatus,
            updatedAt: timestamp,
        }
        await dbUpdateOrder(orderId, newData);
        return true;
    } catch (error) {
        throw error
    }
}

export const getOrdersStats = async () => {
    try {
        const allOrders = await getOrders();
        const paymentsDone = []
        const payoutsDone = []

        const result = {
            totalOrders: allOrders.length + ""
        }

        if (allOrders && allOrders.length > 0) {
            for (let i = 0; i < allOrders.length; ++i) {
                const order = allOrders[i]
                if (order.paymentDoneAt) {
                    paymentsDone.push(order)
                }
                if (order.payoutInitiated) {
                    payoutsDone.push(order)
                }
            }
        }

        result.paymentsDone = paymentsDone.length + ""
        result.payoutsDone = payoutsDone.length + ""

        return result
    } catch (error) {
        throw error
    }
}