import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { ORDER_STATUSES_NAMES, pageNames } from "../helpers/Constants";
import { compareOrders } from "../helpers/HelperFunctions";
import { getAllOrders } from "../service/OrdersService";

const OrdersPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            const orders = await getAllOrders();
            orders.sort(compareOrders)
            setOrders(orders);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert(error);
        }
    }

    const onViewDetails = (order) => {
        navigate(pageNames.orderDetail, {
            state: {
                order: order,
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Orders
                        <small>All Orders</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Orders</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    {loading ?
                                        <LoadingComponent />
                                        : <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Order Id</th>
                                                    <th>Order Status</th>
                                                    <th>Selling Price</th>
                                                    <th>Stripe Fee</th>
                                                    <th>Surfrack Fee</th>
                                                    <th>Payout Amount</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{order.id}</td>
                                                        <td>{ORDER_STATUSES_NAMES[order.orderStatus]}</td>
                                                        <td>{order.sellingPrice}</td>
                                                        <td>{order.stripeFeeAmount}</td>
                                                        <td>{order.surfrackFeeAmount}</td>
                                                        <td>{order.payoutAmount}</td>
                                                        <td>
                                                            <button onClick={() => onViewDetails(order)} className="btn btn-primary">
                                                                View Details
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                        </table>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default OrdersPage;