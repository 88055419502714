import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../components/LoginPage";
import HomePage from "../components/HomePage";
import CitiesPage from "../components/CitiesPage";
import BrandsPage from "../components/BrandsPage";
import SuggestionsPage from "../components/SuggestionsPage";
import ReportedUsersPage from "../components/ReportedUsersPage";
import ChangePasswordPage from "../components/ChangePasswordPage";
import NotFoundPage from "../components/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import { pageNames } from "../helpers/Constants";
import AddCityPage from "../components/AddCity";
import StatesPage from "../components/StatesPage";
import AddStatePage from "../components/AddState";
import AddBrandPage from "../components/AddBrand";
import DeactivatedUsersPage from "../components/DeactivatedUsersPage";
import PayoutsPage from "../components/PayoutsPage";
import PayoutDetailPage from "../components/PayoutDetailPage";
import OrdersPage from "../components/OrdersPage";
import OrderDetailPage from "../components/OrderDetailPage";
import SendPushPage from "../components/SendPushPage";
import SurfboardsPage from "../components/SurfboardsPage";
import UsersPage from "../components/UsersPage";
import UserDetailPage from "../components/UserDetailPage";
import SurfboardDetailPage from "../components/SurfboardDetailPage";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={pageNames.home}
                    element={
                        <PrivateRoute>
                            <HomePage />
                        </PrivateRoute>
                    }
                />
                <Route path={pageNames.loginPage} element={<LoginPage />} />
                <Route
                    path={pageNames.cities}
                    element={
                        <PrivateRoute>
                            <CitiesPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.brands}
                    element={
                        <PrivateRoute>
                            <BrandsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.suggestions}
                    element={
                        <PrivateRoute>
                            <SuggestionsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.reportedUsers}
                    element={
                        <PrivateRoute>
                            <ReportedUsersPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.changePassword}
                    element={
                        <PrivateRoute>
                            <ChangePasswordPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.addCity}
                    element={
                        <PrivateRoute>
                            <AddCityPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.states}
                    element={
                        <PrivateRoute>
                            <StatesPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.addState}
                    element={
                        <PrivateRoute>
                            <AddStatePage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.addBrand}
                    element={
                        <PrivateRoute>
                            <AddBrandPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.deactivatedUsers}
                    element={
                        <PrivateRoute>
                            <DeactivatedUsersPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.payouts}
                    element={
                        <PrivateRoute>
                            <PayoutsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.payoutDetail}
                    element={
                        <PrivateRoute>
                            <PayoutDetailPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.orders}
                    element={
                        <PrivateRoute>
                            <OrdersPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.orderDetail}
                    element={
                        <PrivateRoute>
                            <OrderDetailPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.sendPush}
                    element={
                        <PrivateRoute>
                            <SendPushPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.surfboards}
                    element={
                        <PrivateRoute>
                            <SurfboardsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.users}
                    element={
                        <PrivateRoute>
                            <UsersPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.userDetail}
                    element={
                        <PrivateRoute>
                            <UserDetailPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={pageNames.surfboardDetail}
                    element={
                        <PrivateRoute>
                            <SurfboardDetailPage />
                        </PrivateRoute>
                    }
                />

                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;