import { getStorage, ref, deleteObject } from "firebase/storage";

const storage = getStorage();

export const deleteProfileImage = async (downloadUrl) => {
    try {
        const fileRef = ref(storage, downloadUrl)
        await deleteObject(fileRef)
    } catch (error) {
        throw error;
    }
}