import React from "react";

const FooterComponent = (props) => {
    return (
        <footer className="main-footer">
            <div className="pull-right hidden-xs">
                <b>Version</b> 1.0
            </div>
            <strong>Copyright &copy; 2022 Surf Rack.</strong> All rights reserved.
        </footer>
    )
}

export default FooterComponent;