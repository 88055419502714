import { getFirestore, getDocs, collection } from "firebase/firestore"

const db = getFirestore();
const reportedUsersCollectionName = "reportedUsers";

export const getAllReportedUsers = async () => {
    try {
        const reportedUsersCollection = collection(db, reportedUsersCollectionName);
        const reportedUsersSnapshot = await getDocs(reportedUsersCollection);
        const reportedUsersList = reportedUsersSnapshot.docs.map(doc => doc.data());
        return reportedUsersList;
    } catch (e) {
        throw e
    }
}