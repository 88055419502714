import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as firestore from 'firebase/firestore';
import FooterComponent from "../common/FooterComponent";
import HeaderComponent from "../common/HeaderComponent";
import LeftMenuComponent from "../common/LeftMenu";
import LoadingComponent from "../common/LoadingComponent";
import { UserTypes, pageNames } from "../helpers/Constants";
import { getSurfboardConfig } from "../service/SurfboardService";
import { getDisplayZipCode, getFractionsToDisplay, getLocationBasedOnIdFromArray, getShortDisplayFeetInchesFromInches, getValuesBasedOnIdFromArray } from "../helpers/HelperFunctions";
import { getStates } from "../service/LocationsService";

let surfboardConfig = null
let states = []

const SurfboardDetailPage = (props) => {
    const params = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [surfboard, setSurfboard] = useState(null)

    const getSurfboardData = async (surfboard) => {
        try {
            setLoading(true)
            surfboardConfig = await getSurfboardConfig();
            states = await getStates();
            setSurfboard(surfboard)
            setLoading(false)
        } catch (error) {
            setLoading(false);
            alert(error)
        }
    }

    const onViewDetails = (user) => {
        navigate(pageNames.userDetail, {
            state: {
                user: user
            }
        });
    }

    useEffect(() => {
        if (params && params.state && params.state.surfboard) {
            const surfboard = params.state.surfboard
            getSurfboardData(surfboard)
        }
    }, [])

    return (
        <div className="wrapper">

            <HeaderComponent />

            <LeftMenuComponent />

            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        View Surfboard Details
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li><a href="#">Surfboards</a></li>
                        <li className="active">View Surfboard Details</li>
                    </ol>
                </section>

                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            {loading ?
                                <LoadingComponent />
                                :
                                (surfboard && surfboard.id) ?
                                    <div className="box box-primary">
                                        <div className="box-header">
                                            <h3>Surfboard Details</h3>
                                            <p><span><b>Front Photo - </b></span><img src={surfboard.frontImage} width={100} height={100} alt="Front Image" /></p>
                                            <p><span><b>Back Photo - </b></span><img src={surfboard.backImage} width={100} height={100} alt="Back Image" /></p>
                                            <p><span><b>Side Photo - </b></span><img src={surfboard.sideImage} width={100} height={100} alt="Side Image" /></p>
                                            <p><b>Surfboard Id - {surfboard.id}</b></p>
                                            <p><span><b>Is Available - </b></span>{surfboard.isAvailable ? "Yes" : "No"}</p>
                                            <p><b>Selling Price - {surfboard.sellingPrice ? ("¥ " + surfboard.sellingPrice.toLocaleString()) : ""}</b></p>
                                            <p><b>Location - {getLocationBasedOnIdFromArray(states, surfboard.stateId)}</b></p>
                                            <p><span><b>Added on - </b></span>{new Date(surfboard.createdAt.seconds * 1000).toString()}</p>
                                            <p><span><b>Brand Name - </b></span>{surfboard.brandName}</p>
                                            <p><span><b>Type - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.type, surfboard.type)}</p>
                                            <p><span><b>Length - </b></span>{getShortDisplayFeetInchesFromInches(surfboard.length)}</p>
                                            <p><span><b>Width - </b></span>
                                                {surfboard.width ?
                                                    surfboard.width.inches ?
                                                        (surfboard.width.inches + ' ' + getFractionsToDisplay(surfboard.width.numerator, surfboard.width.denominator))
                                                        : surfboard.width + "\""
                                                    : "N/A"}
                                            </p>
                                            <p><span><b>Thickness - </b></span>
                                                {surfboard.thickness ?
                                                    surfboard.thickness.inches ?
                                                        (surfboard.thickness.inches + ' ' + getFractionsToDisplay(surfboard.thickness.numerator, surfboard.thickness.denominator))
                                                        : surfboard.thickness + "\""
                                                    : "N/A"}
                                            </p>
                                            <p><span><b>Condition - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.condition, surfboard.condition)}</p>
                                            <p><span><b>Liters - </b></span>{surfboard.liters ? surfboard.liters : "N/A"}</p>
                                            <p><span><b>Material - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.materials, surfboard.material)}</p>
                                            <p><span><b>Fin Setup - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.finSetup, surfboard.finSetup)}</p>
                                            <p><span><b>FinBoxy Type - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.finBoxType, surfboard.finBoxType)}</p>
                                            <p><span><b>Recommendation Level - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.recommendationLevel, surfboard.recommendationLevel)}</p>
                                            <p><span><b>Tail Type - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.tailType, surfboard.tailType)}</p>
                                            <p><span><b>Description - </b></span>{surfboard.description ? surfboard.description : ""}</p>
                                            <p><span><b>Shipping Burden - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.shippingBurden, surfboard.shippingBurden)}</p>
                                            <p><span><b>Distribution Method - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.shippingMethod, surfboard.shippingMethod)}</p>
                                            <p><span><b>Days To Deliver - </b></span>{getValuesBasedOnIdFromArray(surfboardConfig.daysToDeliver, surfboard.daysToDeliver)}</p>
                                            <p><span><b>Zip Code - </b></span>{surfboard.zipCode ? getDisplayZipCode(surfboard.zipCode) : "N/A"}</p>

                                            <h3>Posted By</h3>
                                            <p><span><b>Name - </b></span>{surfboard.user.name}</p>
                                            <p><span><b>Email - </b></span>{surfboard.user.email}</p>
                                            <p><span><b>Phone Number - </b></span>{surfboard.user.phoneNumber}</p>
                                            <p><span><b>Username - </b></span>{surfboard.user.username}</p>
                                            <p><span><b>User Type - </b></span>{surfboard.user.userType === UserTypes.USER ? 'User' : 'Shop'}</p>
                                            <button onClick={() => onViewDetails(surfboard.user)} className="btn btn-primary">
                                                View Details
                                            </button>

                                            <h3>Other Photos</h3>
                                            {surfboard.otherImages.map((image, index) => (
                                                <img src={image} width={100} height={100} alt="Surfboard Image" />
                                            )
                                            )}
                                        </div>
                                    </div>
                                    :
                                    <div className="box box-primary">
                                        <LoadingComponent />
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </div>
    );
}

export default SurfboardDetailPage;