import { getFirestore, doc, getDoc } from "firebase/firestore"

const db = getFirestore();
const collectionName = "admins";

export const getUserById = async (userId) => {
    try {
        const userRef = doc(db, collectionName, userId);
        const userSnap = await getDoc(userRef);
        if(userSnap.exists()) {
            return userSnap.data();
        } else {
            throw "No such Admin"
        }
    } catch (e) {
        throw e
    }
}