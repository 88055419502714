import { getFirestore, getDocs, collection } from "firebase/firestore"

const db = getFirestore();
const suggestionsCollectionName = "suggestions";

export const getAllSuggestions = async () => {
    try {
        const suggestionsCollection = collection(db, suggestionsCollectionName);
        const suggestionsSnapshot = await getDocs(suggestionsCollection);
        const suggestionsList = suggestionsSnapshot.docs.map(doc => doc.data());
        return suggestionsList;
    } catch (e) {
        throw e
    }
}