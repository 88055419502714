import { addBrandToFirestore, getAllBrands, updateBrandToFirestore } from '../firebaseDao/BrandsDao';

export const addBrand = async (nameEN, nameJA) => {
    try {
        await addBrandToFirestore(nameEN, nameJA);
    } catch (error) {
        throw error
    }
}

export const getBrands = async () => {
    try {
        return await getAllBrands();
    } catch (error) {
        throw error
    }
}

export const updateBrand = async (id, nameEN, nameJA) => {
    try {
        await updateBrandToFirestore(id, nameEN, nameJA);
    } catch (error) {
        throw error
    }
}