import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { pageNames } from '../helpers/Constants';
import { checkIfUserLoggedIn } from '../service/UserService';

const PrivateRoute = ({ children }) => {
    const [shouldProceed, setShouldProceed] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        checkIfUserLoggedIn((user) => {
            if (user) {
                // Logged In
                setIsLoggedIn(true);
            } else {
                // Not logged in
                setIsLoggedIn(false);
            }
            setShouldProceed(true);
        });
    }, []);

    return (
        shouldProceed ?
            isLoggedIn ? children : <Navigate replace to={pageNames.loginPage} />
            : <></>
    )
};

export default PrivateRoute;